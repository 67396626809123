import { LineItemModel, ModelAbstract } from './index';
import apiData from '../Storage/apiData';
import { ORDER_TYPES, USER_ROLES } from '../common/enums';
import { AuthContext } from '../Context';
import fixFloat from '../common/helpers/fixFloat';
import config from '../config';
import bindMultiple from '../common/helpers/bindMultiple';

class AddLineItemsGlobalDialogModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.isOpen = false;
    this.showFixed = false;
    this.showHidden = false;

    this._ignoreOnExport.push('masterOrder', 'quotesCollection');

    bindMultiple(this, this.handleDialogOpen, this.onDialogClose, this.onAddButtonClick);
  }

  handleDialogOpen() {
    this.isOpen = true;
    this.forceRender();
  }

  onDialogClose() {
    this.isOpen = false;
    this.forceRender();
  }

  onAddButtonClick(lineItemIds) {
    this.isOpen = false;

    for (let i = 0; i < lineItemIds.length; i++) {
      const lineItemId = lineItemIds[i];
      const data = apiData.lineItems.find(d => d.lineItemId === lineItemId);

      if (!data) {
        continue;
      }

      if (this.masterOrder.orderType === ORDER_TYPES.REDUCTION) {
        data.quantity = 0;
      }

      const doFocus = i === lineItemIds.length - 1;

      this.masterOrder.lineItems.add({
        ...data,
        doFocus,
        focusField: 'quantity',
        existing: false,
      });
    }

    const locationModels = this.masterOrder.locations;

    for (let i = 0; i < locationModels.length; i++) {
      locationModels[i].syncLineItems();
    }

    this.modelChanged(this);
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    return this._sibling.masterOrder;
  }

  /**
   * @returns {QuotesCollectionModel}
   */
  get quotesCollection() {
    return this._sibling.quotes;
  }

  get quoteLineItemIds() {
    return this.masterOrder.lineItems.items.map(lineItem => lineItem.lineItemId);
  }

  get _lineItems() {
    const lineItemsApiData = JSON.parse(JSON.stringify(apiData.lineItems));

    const selectedQuoteIndex = this.quotesCollection.selectedQuote.index;
    const solutionTypeId = this.quotesCollection.items[selectedQuoteIndex].solutionTypeId;

    const catalog = lineItemsApiData.filter(lineItemApiData => {
      if (!lineItemApiData.active || !lineItemApiData.allowedToAdd) {
        return false;
      }

      // TODO: Filter out invisible categories. Ex: ICTPPS

      // KM-8584: Filter out suppressed items for non SalesOps users
      if (
        AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER &&
        this.masterOrder.suppressedLineItemIds.includes(lineItemApiData.lineItemId)
      ) {
        return false;
      }

      // KM-5766: Does not show line items with flag isFixedOnUi in Add Line Items dialog
      const isFixedOnUi = lineItemApiData.catalogFlags.find(f => f.flag.name === 'isFixedOnUi');

      if (isFixedOnUi?.valueAsString?.toLowerCase() === 'true' && !this.showFixed) {
        return false;
      }

      if (!this.showHidden) {
        // KM-6125: Filter out items that has specificForPackageX
        // But not match currently selected package ID
        let hasAnyPerPackageFlag = false;

        for (let i = 1; i <= 4; i++) {
          if (lineItemApiData.catalogFlags.some(f => f.flag.name === 'specificForPackage' + i)) {
            hasAnyPerPackageFlag = true;
            break;
          }
        }

        if (hasAnyPerPackageFlag) {
          const specificForPackageFlag = lineItemApiData.catalogFlags.find(
            f => f.flag.name === 'specificForPackage' + this.masterOrder.packageIdSelected
          );

          if (
            !specificForPackageFlag ||
            !specificForPackageFlag.valueAsString ||
            specificForPackageFlag.valueAsString.toLowerCase() === 'false'
          ) {
            return false;
          }
        }
      }

      const forbidAddingIfPropertyEqualsTrue = lineItemApiData.catalogFlags.find(
        f => f.flag.name === 'forbidAddingIfPropertyEqualsTrue'
      );

      if (forbidAddingIfPropertyEqualsTrue) {
        // Reverse logic if model property equals true it should not be selectable
        // filter function should return false
        if (this.masterOrder[forbidAddingIfPropertyEqualsTrue.valueAsString] === true) {
          return false;
        }
      }

      // KM-6482: Filter line items by telecom solution type
      // When Add Line Item dialog shown on location quote level
      if (this.performSolutionTypeFilter && !lineItemApiData.applicableSolutionTypes.includes(solutionTypeId)) {
        return false;
      }

      // KM-6391: Gather MO getters from allowedToAddOnCondition flag
      // Evaluate them to match valueAsString condition
      const allowedToAddOnConditionResult = LineItemModel.validateAllowedToAddOnConditionFlags({
        lineItemFlags: lineItemApiData.catalogFlags,
        productId: lineItemApiData.productId,
        masterOrderModel: this.masterOrder,
        returnNullIfNoFlags: true,
      });

      // TODO: If "selectable" check will be moved to the top then remove "if (typeof ...) {}" scope
      if (typeof allowedToAddOnConditionResult === 'boolean') {
        // KM-11207: Show line item in Add Line Item dialog if it has flag previewItemsNotAllowedToAddOnCondition
        if (
          allowedToAddOnConditionResult === false &&
          !lineItemApiData.catalogFlags.some(
            flagApiData =>
              flagApiData.flag.name === 'previewItemsNotAllowedToAddOnCondition' &&
              flagApiData.valueAsString.toLowerCase() === 'true'
          )
        ) {
          return false;
        }
      }

      const onlyAllowedAsExistingItem = lineItemApiData.catalogFlags.some(
        flagApiData =>
          flagApiData.flag.name === 'productTag' && flagApiData.valueAsString === 'onlyAllowedAsExistingItem'
      );

      if (onlyAllowedAsExistingItem && !this.isForExistingItems) {
        return false;
      }

      const ishiddenItem = lineItemApiData.catalogFlags.find(f => f.flag.name === 'hiddenItem');

      if (
        ishiddenItem &&
        typeof ishiddenItem.valueAsString === 'string' &&
        ishiddenItem.valueAsString.toLowerCase() === 'true' &&
        !this.showHidden
      ) {
        return false;
      }

      // TODO: "selectable" check should probably be moved to very top on par with "active"
      //       But we need to ensure there are no cases where:
      //       (allowedToAddOnConditionResult === true && lineItemApiData.selectable === false)
      return lineItemApiData.selectable;
    });

    // KM-5155: In case bundle item has zero dealerNet
    // Calc bundle price as a total recursive SUM of it sub items dealerNet values
    for (let i = 0; i < catalog.length; i++) {
      let item = catalog[i];

      item._isRecurring = this._isLineItemRecurring(item);
      item._bundleDealerNet = item.bundleType === 'COMPOSITE' ? this._calcBundleDealerNet(item) : null;

      if (item._bundleDealerNet > 0 && !this.hasBundles) {
        this.hasBundles = true;
      }

      // KM-11207: Items with allowedToAddOnCondition === false && previewItemsNotAllowedToAddOnCondition === true flags
      //           should have disabled checkboxes
      const allowedToAddOnCondition = LineItemModel.validateAllowedToAddOnConditionFlags({
        lineItemFlags: item.catalogFlags,
        // TODO: Comparing against "lineItem." may have unexpected effect in Add Line Item dialog
        productId: item.productId,
        masterOrderModel: this.masterOrder,
      });
      const previewItemsNotAllowedToAddOnCondition = item.catalogFlags.some(
        flagApiData =>
          flagApiData.flag.name === 'previewItemsNotAllowedToAddOnCondition' &&
          flagApiData.valueAsString.toLowerCase() === 'true'
      );

      item._forceDisableItemSelection = !allowedToAddOnCondition && previewItemsNotAllowedToAddOnCondition;
    }

    return catalog;
  }

  get lineItems() {
    return this._getCachedValueOnExport('_lineItems');
  }

  get performSolutionTypeFilter() {
    const selectedQuoteIndex = this.quotesCollection.selectedQuote.index;

    return Boolean(selectedQuoteIndex) || this.masterOrder.orderType === ORDER_TYPES.ADD_ON;
  }

  get isSolutionTypeSame() {
    // No need to do isSolutionTypeSame validation if there is no or one item available
    if (this.lineItems.length > 1) {
      const firstItemApplicableSolutionTypes = this.lineItems[0].applicableSolutionTypes;

      for (let i = 0; i < this.lineItems.length; i++) {
        const lineItem = this.lineItems[i];
        const solutionTypesMatches =
          firstItemApplicableSolutionTypes.length === lineItem.applicableSolutionTypes.length &&
          firstItemApplicableSolutionTypes.every(solutionType =>
            lineItem.applicableSolutionTypes.includes(solutionType)
          );

        if (!solutionTypesMatches) {
          return false;
        }
      }
    }

    return true;
  }

  get showSolutionTypeMark() {
    return this.performSolutionTypeFilter === false && !this.isSolutionTypeSame;
  }

  _calcBundleDealerNet(parent, level = 0) {
    let dealerNet = 0;
    const subLineItems = parent.subLineItems;

    if (!subLineItems.length) {
      // Top level item is not a bundle
      if (level === 0) {
        return null;
      }

      // Else return child's dealerNet value
      return parent.dealerNet;
    }

    const isParentRecurring = this._isLineItemRecurring(parent);

    // Bundle has it own dealerNet
    if (parent.dealerNet !== 0) {
      // Return null for bundle price if it is a top level item
      if (level === 0) {
        return null;
      }

      // Else add bundle dealerNet value into SUM
      dealerNet = fixFloat(dealerNet) + fixFloat(parent.dealerNet);
    }

    for (let i = 0; i < subLineItems.length; i++) {
      const childId = subLineItems[i].subLineItemId;
      const child = apiData.lineItems.find(d => d.lineItemId === childId);

      // If child not found it indicates DB broken links
      if (!child) {
        if (config.showConsoleLog) {
          console.warn(
            `Line Item [${parent.lineItemId}] has sub Line Item [${childId}] link. But child data missing in API response.`
          );
        }

        continue;
      }

      const isChildRecurring = this._isLineItemRecurring(child);

      // [Non]Recurring type of parent and child should match
      if (isParentRecurring !== isChildRecurring) {
        // Skip child item if types does not match
        continue;
      }

      dealerNet += this._calcBundleDealerNet(child, level + 1);
    }

    return dealerNet;
  }

  _isLineItemRecurring(lineItem) {
    const category = apiData.categories.find(d => d.id === lineItem.lineItemCategoryId);

    return category === undefined ? null : category.recurring;
  }
}

export default AddLineItemsGlobalDialogModel;
