import {
  ModelAbstract,
  ServiceInfoModel,
  SpiffDiscountModel,
  DateModel,
  ProvidersGroupModel,
  ShippingInfoModel,
  BillingInfoModel,
  CalcResultsModel,
} from './';
import { PartnerContext, PriceBookContext } from '../Context';
import { LINE_TYPES, PACKAGE_TYPE_IDS, PRICEBOOK_TAGS, QUOTE_STATUS } from '../common/enums';

class QuoteModel extends ModelAbstract {
  constructor(parent) {
    super(parent);

    this._id = null;
    this._useNumLinesDefault = true;
    this._numLines = null;
    this._customerName = null;
    this._dealerContacts = [];
    this._dealerContactUserId = null;
    this._numLocations = 1;
    this._numStarCloudLocations = 0;
    this._trackingLocationId = null;
    this._quoteStatus = QUOTE_STATUS.OPEN;
    this._billingDealerId = null;
    this._salesExceptions = [];
    this._checkpoints = [];
    this._signatureHistory = [];
    this._numLinesMinimumAllowed = 2;
    this._useSOBO = false;
    this.subAgentName = null;

    this.dba = null;
    this.useDba = false;
    this.locationPackageType = null;
    this.taxExempt = null;
    this.statusBeforeSalesException = null;
    this.numLocationsCountWarning = false;

    this.dealerId = null;
    this.dealerName = null;
    this.dealerIsAgent = true;
    this.dealerContactName = null;
    this.dealerContactEmail = null;
    this.quoteTitle = null;
    this.isCustomerNameChanged = false;
    this.lineType = LINE_TYPES.UNLIMITED;
    this.perExtensionPricing = false;
    this.bulkRecurring = null;
    this.bulkNonRecurring = null;
    this.numSwitchvoxOnPremUsers = 0;
    this.packageIdSelected = 1;
    this.star2starLocationId = null;

    // Timestamp of creation and last update
    // Received from BE on quote retrieve
    // If quote saved once then created === updated
    this.created = null;
    this.updated = null;

    this.ictpDates = new DateModel(this);
    this.ictpProviders = new ProvidersGroupModel(this);
    this.spiffDiscount = new SpiffDiscountModel(this);
    this.serviceInfo = new ServiceInfoModel(this);
    this.shippingInfo = new ShippingInfoModel(this);
    this.billingInfo = new BillingInfoModel(this);
    this.calcResults = new CalcResultsModel(this);
    /** @type {LineItemsCollectionModel|null} */
    this.lineItems = null;

    this.onChange = this.onChange.bind(this);
  }

  get appState() {
    if (!this.controller) {
      return null;
    }

    return this.controller.appState;
  }

  get useSOBO() {
    return this._useSOBO;
  }
  set useSOBO(value) {
    if (!value) {
      this.subAgentName = null;
    }
    this._useSOBO = value;
  }

  get salesExceptions() {
    return this._salesExceptions;
  }
  set salesExceptions(value) {
    this._salesExceptions = value;
  }

  get checkpoints() {
    return this._checkpoints;
  }
  set checkpoints(value) {
    this._checkpoints = value;
  }

  get signatureHistory() {
    return this._signatureHistory;
  }
  set signatureHistory(value) {
    this._signatureHistory = value;
  }

  get dateInstall() {
    return this.ictpDates.dateInstall;
  }
  set dateInstall(value) {
    this.ictpDates.dateInstall = value;
  }

  get dateShippingRequested() {
    return this.ictpDates.dateShippingRequested;
  }
  set dateShippingRequested(value) {
    this.ictpDates.dateShippingRequested = value;
  }

  get dealerContacts() {
    return this._dealerContacts;
  }
  set dealerContacts(value) {
    if (this._dealerContacts.length === 0) {
      this._dealerContacts = value;

      if (value.length === 1) {
        this.dealerContactUserId = value[0].userId;
      }
    } else {
      this._dealerContacts = value;

      if (value.length === 1) {
        this.dealerContactUserId = value[0].userId;
      } else {
        this.dealerContactName = '';
        this.dealerContactEmail = '';
      }
    }
  }

  get customerName() {
    return this._customerName;
  }
  set customerName(value) {
    this.isCustomerNameChanged = this._customerName !== value;
    this._customerName = value;

    if (this.id) {
      document.title = ['RQ ' + this.id, this._customerName, 'Rocket®'].join(' - ');
    }
  }

  get dealerContactUserId() {
    return this._dealerContactUserId;
  }
  set dealerContactUserId(_value) {
    const value = Number(_value);

    if (!this.isCustomerNameChanged) {
      this.isCustomerNameChanged = this._dealerContactUserId !== value;
    }

    this._dealerContactUserId = value;

    // KM-8347: Reset Quote Owner Name/Email props to empty string
    // If no matching value is selected from Quote Owner Name drop-down (which is bound to dealerContactUserId)
    if (_value === null) {
      this.dealerContactName = '';
      this.dealerContactEmail = '';
    } else {
      for (let i = 0; i < this.dealerContacts.length; i++) {
        if (value !== this.dealerContacts[i]['userId']) {
          continue;
        }

        let user = this.dealerContacts[i];

        this.dealerContactName = user.firstName + ' ' + user.lastName;
        this.dealerContactEmail = user.email;

        break;
      }
    }
  }

  get _numTotalExtensionsCalc() {
    let result = 0;

    for (let i = 0; i < this.lineItems.items.length; i++) {
      const lineItem = this.lineItems.items[i];

      if (!lineItem.extensionFactor) {
        continue;
      }

      result += lineItem.quantity * lineItem.extensionFactor;
    }

    return result;
  }

  get numTotalExtensions() {
    return this._getCachedValueOnExport('_numTotalExtensionsCalc');
  }

  get _numHostedUsersCalc() {
    let result = 0;

    for (let i = 0; i < this.lineItems.items.length; i++) {
      const lineItem = this.lineItems.items[i];

      if (!lineItem.hostedUserFactor) {
        continue;
      }

      result += lineItem.quantity * lineItem.hostedUserFactor;
    }

    return result;
  }

  get numHostedUsers() {
    return this._getCachedValueOnExport('_numHostedUsersCalc');
  }

  get minLines() {
    const extensionsPerLineMax = 8;

    return Math.max(
      this._numLinesMinimumAllowed,
      Math.ceil(this.numTotalNonCourtesyExtensionsWithOverride / extensionsPerLineMax)
    );
  }

  get maxLines() {
    const minLines = this.minLines;
    const extensions = this.numTotalNonCourtesyExtensionsWithOverride;

    return extensions < minLines ? minLines : extensions;
  }

  get numLinesDefault() {
    const extensionsPerLine = 3;

    return Math.max(this.minLines, Math.ceil(this.maxLines / extensionsPerLine));
  }

  get useNumLinesDefault() {
    if (PartnerContext.model.isWholeSale || PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    if (
      PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue !== undefined ||
      [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(this.packageIdSelected)
    ) {
      return false;
    }

    return this._useNumLinesDefault;
  }

  set useNumLinesDefault(value) {
    this._useNumLinesDefault = Boolean(value);
    this._numLines = this.numLinesDefault;
  }

  get numLocations() {
    return this._numLocations;
  }

  set numLocations(value) {
    this._numLocations = value;
  }

  get numStarCloudLocations() {
    if (this.numLocations < this._numStarCloudLocations) {
      this._numStarCloudLocations = this.numLocations;
    }

    return this._numStarCloudLocations;
  }

  set numStarCloudLocations(value) {
    this._numStarCloudLocations = Number(value) || 0;
  }

  get numLines() {
    if (PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue !== undefined) {
      return PriceBookContext.model.flags.hideNumLinesControlsWithDefaultValue;
    }

    if (
      PriceBookContext.model.isSupportsSwitchvoxSIPStation ||
      [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(this.packageIdSelected)
    ) {
      return 0;
    }

    if (this._numLines === null || this.useNumLinesDefault) {
      return this.numLinesDefault;
    }

    return this._numLines;
  }

  set numLines(value) {
    this._numLines = value;
  }

  get confirmedSpiffDiscount() {
    return this.spiffDiscount.isConfirmed || false;
  }

  set confirmedSpiffDiscount(value) {
    this.spiffDiscount.isConfirmed = Boolean(value);
  }

  get isSpiffConfirmationValid() {
    return (
      PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.NO_MAD_APPLIES_UNLESS_SALESOPS_OVERRIDE) ||
      this.confirmedSpiffDiscount
    );
  }

  get zeroPartnerPricesAck() {
    return this.ictpProviders.isConfirmed || false;
  }

  set zeroPartnerPricesAck(value) {
    this.ictpProviders.isConfirmed = Boolean(value);
  }

  get spiffAmount() {
    return this.spiffDiscount.spiffAmount || 0;
  }

  set spiffAmount(value) {
    this.spiffDiscount.spiffAmount = value;
  }

  get madRecurPrcnt() {
    if (PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.APPLY_ALL_MAD_TO_RECURRING_WITHOUT_SLIDER)) {
      return 100;
    }

    return this.spiffDiscount.sliderValue || 0;
  }

  set madRecurPrcnt(value) {
    this.spiffDiscount.sliderValue = value;
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;
    }

    this.modelChanged(this);
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = Number(value) || null;
  }

  get trackingLocationId() {
    return this._trackingLocationId;
  }

  set trackingLocationId(value) {
    this._trackingLocationId = parseInt(value, 10) || null;
  }

  get quoteStatus() {
    return this._quoteStatus;
  }

  set quoteStatus(value) {
    this._quoteStatus = typeof value === 'string' ? value.toUpperCase() : value;
  }

  get billingDealerId() {
    return this._billingDealerId;
  }

  set billingDealerId(value) {
    value = Number(value);

    this._billingDealerId = !isNaN(value) ? value : null;
  }

  get locationName() {
    return this.serviceInfo.locationName;
  }
  set locationName(value) {
    this.serviceInfo.locationName = value;
  }

  get _numTotalNonCourtesyExtensionsWithOverrideCalc() {
    let result = 0;

    for (let i = 0; i < this.lineItems.items.length; i++) {
      /** @type {LineItemModel} */
      const lineItem = this.lineItems.items[i];

      // Skip negative values by business rule
      // Skip zero value also. There is no need to do SUM operator with zero value
      // Use `numPortsWithOverridePerItem` which does not take quantity into account
      // Using `numPortsWithOverride` that returns total number may end with infinite loop
      // because quantity is a getter and it may use `extensionFactor` (in)directly
      // and `extensionFactor` is a base value for `numPortsWithOverridePerItem`
      // Also skip items with zero quantity
      if (lineItem.numPortsWithOverridePerItem < 1 || lineItem.quantity === 0) {
        continue;
      }

      // Here we need `numPortsWithOverride` to SUM up totals
      result += lineItem.numPortsWithOverride;
    }

    return result;
  }

  get numTotalNonCourtesyExtensionsWithOverride() {
    return this._getCachedValueOnExport('_numTotalNonCourtesyExtensionsWithOverrideCalc');
  }

  get numMeteredLines() {
    return this.lineType === LINE_TYPES.METERED ? this.numLines : 0;
  }

  get numUnlimitedLines() {
    return this.lineType === LINE_TYPES.UNLIMITED ? this.numLines : 0;
  }

  get _numPortsWithOverrideTotalLookup() {
    let result = 0;

    for (let i = 0; i < this.lineItems.items.length; i++) {
      /** @type {LineItemModel} */
      const lineItem = this.lineItems.items[i];

      // Skip items with zero ports or quantity
      if (lineItem.numPortsWithOverridePerItem === 0 || lineItem.quantity === 0) {
        continue;
      }

      // Here we need numPortsWithOverride because it is already multiplied by quantity
      result += lineItem.numPortsWithOverride;
    }

    return result;
  }

  get numPortsWithOverrideTotal() {
    return this._getCachedValueOnExport('_numPortsWithOverrideTotalLookup');
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {number}
   */
  get numSwitchvoxOnPremUsersValue() {
    return this.numSwitchvoxOnPremUsers;
  }
}

export default QuoteModel;
