import AppRouter from './AppRouter';
import { CreateQuoteLayout, LandingPageLayout, QuotePageLayout, QuotesRetrieveLayout } from '../Layouts';
import {
  LandingPageController,
  NotFoundController,
  OrderInitiationController,
  QuotesTableController,
  QuoteController,
  SalesForceQuoteInitController,
} from '../Controllers';
import { AuthContext, ErrorContext, PartnerContext, PriceBookContext } from '../Context';
import {
  ERROR_BANNER_IDS,
  ERROR_CONTEXT_TYPES,
  ORDER_TYPES,
  ORDER_TYPES_LINKS,
  QUOTE_SERVICE_TYPE,
} from '../common/enums';
import NotFoundLayout from '../Layouts/NotFoundLayout';
import OriginalQuoteController from '../Controllers/OriginalQuoteController';

const ACTION_OPTIONS = {
  [ORDER_TYPES_LINKS.EXISTING_CUSTOMER_NEW_LOCATION]: 2,
  [ORDER_TYPES_LINKS.ADDON_TO_EXISTING_LOCATION]: 3,
  [ORDER_TYPES_LINKS.REWRITE]: 4,
};

const Routes = [
  {
    match: /^.*[?&]token=([A-z\-0-9]+)/,
    onMatch: ({ matches, location }) => {
      const urlToken = matches[1];

      if (AuthContext.model.token !== urlToken) {
        // Clear local storage data to use default settings for newly logged users
        localStorage.clear();

        AuthContext.model.token = urlToken;
      }

      location.search = location.search.replace(/[?&]token=([A-z\-0-9]+)/, '');

      AppRouter.replace(location);
    },
  },
  // Root path "/"
  {
    match: /^\/$/,
    onMatch: () => AppRouter.replace('/landing'),
  },
  // /landing
  {
    match: /^\/landing$/,
    onMatch: () => {
      document.title = 'Rocket®';
      PriceBookContext.model.clearPBContext();
      PartnerContext.model.clearPartnerContext();
      ErrorContext.model.setProps({
        id: ERROR_BANNER_IDS.QUOTE_ANNOUNCEMENT_BANNER,
        isShown: false,
      });
    },
    getController: () => new LandingPageController(),
    view: LandingPageLayout,
  },
  {
    match: /^\/create$/,
    onMatch: ({ location }) => {
      document.title = 'Create Quote - Rocket®';
      PriceBookContext.model.clearPBContext();
      PartnerContext.model.clearPartnerContext();
      ErrorContext.model.setProps({
        id: ERROR_BANNER_IDS.QUOTE_ANNOUNCEMENT_BANNER,
        isShown: false,
      });
    },
    getController: ({ location }) => {
      const controller = new OrderInitiationController();

      if (location.state) {
        controller.order.selectedPartnerId = location.state.partnerId;
        controller.order.selectedAction = ACTION_OPTIONS[location.state.orderType];
      }

      return controller;
    },
    view: CreateQuoteLayout,
  },
  // /create/{originalQuoteId}/{actionType}
  {
    match: /^\/create\/(\d+)\/([A-z-]+)$/,
    onMatch: () => {
      document.title = 'Create Quote - Rocket®';
      PriceBookContext.model.clearPBContext();
      PartnerContext.model.clearPartnerContext();
    },
    getController: ({ location, matches }) => {
      const controller = new OrderInitiationController();
      const originalQuoteId = Number(matches[1]);
      const actionType = matches[2];

      if (location.state && location.state.resetOverrides !== undefined) {
        controller.resetOverrides = location.state.resetOverrides;
      }

      controller.originalQuoteId = originalQuoteId;
      controller.order.selectedAction = ACTION_OPTIONS[actionType];

      // lock all actions
      controller.order.actionOptionsLocked = Object.keys(controller.order.actions).map(opt => Number(opt));

      return controller;
    },
    view: CreateQuoteLayout,
  },
  {
    match: /^\/opportunity\/([A-z0-9]{18})\/create/,
    onMatch: () => {
      document.title = 'Create Quote - Rocket®';
    },
    getController: ({ matches }) => {
      const opportunityId = matches[1];
      const controller = new SalesForceQuoteInitController(opportunityId);

      return controller;
    },
    view: CreateQuoteLayout,
  },
  // /opportunity/{opportunityId}/quote
  {
    match: /^\/opportunity\/([A-z0-9]{15,18})\/quote(?:\?(.+))?/,
    onMatch: () => {
      document.title = 'Sales Force Quote - Rocket®';
    },
    getController: ({ matches }) => {
      const opportunityId = matches[1];
      const queryParams = new URLSearchParams(matches[2]);
      const industryCodeId = Number(queryParams.get('industryCodeId'));
      const switchvoxSipStation = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.swvxSipstation;
      const standaloneServiceNonUCaaS = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      const serviceType = queryParams.get('serviceType');

      const params = {
        opportunityId,
        switchvoxSipStation,
        standaloneServiceNonUCaaS,
        serviceType,
        industryCodeId,
      };

      return new QuoteController(params);
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.NEW_CUSTOMER },
  },
  // /partners/{partnerId}/new-customer
  {
    match: /^\/partners\/(\d+)\/new-customer(?:\?(.+))?/,
    onMatch: () => {
      document.title = 'New/New - Rocket®';
    },
    getController: ({ matches }) => {
      const orderType = ORDER_TYPES.NEW_CUSTOMER;
      const partnerId = Number(matches[1]);
      const queryParams = new URLSearchParams(matches[2]);
      const switchvoxSipStation = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.swvxSipstation;
      const standaloneServiceNonUCaaS = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      const serviceType = queryParams.get('serviceType');
      const industryCodeId = Number(queryParams.get('industryCodeId'));

      const params = {
        orderType,
        partnerId,
        switchvoxSipStation,
        standaloneServiceNonUCaaS,
        serviceType,
        industryCodeId,
      };

      return new QuoteController(params);
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.NEW_CUSTOMER },
  },
  // /partners/{partnerId}/customers/{customerId}/locations/new
  {
    match: /^\/partners\/(\d+)\/customers\/(\d+)\/locations\/new(?:\?(.+))?/,
    onMatch: () => {
      document.title = 'Existing/New - Rocket®';
    },
    getController: ({ matches }) => {
      const orderType = ORDER_TYPES.NEW_LOCATIONS;
      const partnerId = Number(matches[1]);
      const customerId = Number(matches[2]);
      const queryParams = new URLSearchParams(matches[3]);
      const standaloneServiceNonUCaaS = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      const serviceType = queryParams.get('serviceType');

      const params = {
        orderType,
        partnerId,
        customerId,
        standaloneServiceNonUCaaS,
        serviceType,
      };

      return new QuoteController(params);
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.NEW_LOCATIONS },
  },
  // /partners/{partnerId}/customers/{customerId}/locations/{locationId}/add-on
  {
    match: /^\/partners\/(\d+)\/customers\/(\d+)\/locations\/(\d+)\/add-on(:?\?(.+))?/,
    onMatch: () => {
      document.title = 'Add-On - Rocket®';
    },
    getController: ({ matches }) => {
      const orderType = ORDER_TYPES.ADD_ON;
      const partnerId = Number(matches[1]);
      const customerId = Number(matches[2]);
      const locationId = Number(matches[3]);
      const queryParams = new URLSearchParams(matches[4]);
      const businessContinuity = Boolean(Number(queryParams.get('bc')));
      const standaloneServiceNonUCaaS = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      const serviceType = queryParams.get('serviceType');

      const params = {
        orderType,
        partnerId,
        customerId,
        locationIds: [locationId],
        businessContinuity,
        standaloneServiceNonUCaaS,
        serviceType,
      };

      return new QuoteController(params);
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.ADD_ON },
  },
  // /partners/{partnerId}/customers/{customerId}/rewrite
  {
    match: /^\/partners\/(\d+)\/customers\/(\d+)\/rewrite(:?\?(.+))?/,
    onMatch: () => {
      document.title = 'Rewrite - Rocket®';
    },
    getController: ({ matches }) => {
      const orderType = ORDER_TYPES.REWRITE;
      const partnerId = Number(matches[1]);
      const customerId = Number(matches[2]);
      const queryParams = new URLSearchParams(matches[3]);
      const businessContinuity = Boolean(Number(queryParams.get('bc')));
      const locationIds = (queryParams.get('locationIds') || '').split(',').map(locationId => Number(locationId));
      const standaloneServiceNonUCaaS = queryParams.get('serviceType') === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      const serviceType = queryParams.get('serviceType');

      const params = {
        orderType,
        partnerId,
        customerId,
        locationIds,
        businessContinuity,
        standaloneServiceNonUCaaS,
        serviceType,
      };

      return new QuoteController(params);
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.REWRITE },
  },
  // /originalId/{originalQuoteId}/partners/{partnerId}/customers/{customerId}/locations/new
  {
    match: /^\/originalId\/(\d+)\/partners\/(\d+)\/customers\/(\d+)\/locations\/new/,
    onMatch: () => {
      document.title = 'Existing/New - Rocket®';
    },
    getController: ({ matches, location }) => {
      const orderType = ORDER_TYPES.NEW_LOCATIONS;
      const params = {
        orderType,
        originalQuoteId: Number(matches[1]),
        partnerId: Number(matches[2]),
        customerId: Number(matches[3]),
      };

      const controller = new QuoteController(params);

      if (location.state && location.state.resetOverrides !== undefined) {
        controller.resetOverrides = location.state.resetOverrides;
      }

      return controller;
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.NEW_LOCATIONS },
  },
  // /originalId/{originalQuoteId}/partners/{partnerId}/customers/{customerId}/locations/{locationId}/add-on
  {
    match: /^\/originalId\/(\d+)\/partners\/(\d+)\/customers\/(\d+)\/locations\/(\d+)\/add-on(?:.*?[?&]bc=(\d))?/,
    onMatch: ({ location, matches }) => {
      document.title = 'Add-On - Rocket®';
    },
    getController: ({ matches, location }) => {
      const orderType = ORDER_TYPES.ADD_ON;
      const params = {
        orderType,
        originalQuoteId: Number(matches[1]),
        partnerId: Number(matches[2]),
        customerId: Number(matches[3]),
        locationIds: [Number(matches[4])],
        businessContinuity: Boolean(Number(matches[5])),
      };

      const controller = new QuoteController(params);

      if (location.state && location.state.resetOverrides !== undefined) {
        controller.resetOverrides = location.state.resetOverrides;
      }

      return controller;
    },
    view: QuotePageLayout,
    viewProps: { orderType: ORDER_TYPES.ADD_ON },
  },
  {
    match: /^\/quotes([?&](\w*=[\w,-.%]*&?)+)?$/,
    onMatch: () => {
      document.title = 'Retrieve Quotes - Rocket®';
      PriceBookContext.model.clearPBContext();
      PartnerContext.model.clearPartnerContext();

      if (ErrorContext.model.isShown && ErrorContext.model.type !== ERROR_CONTEXT_TYPES.ERROR) {
        ErrorContext.model.setProps({
          isShown: false,
        });
      }
    },
    getController: () => new QuotesTableController(),
    view: QuotesRetrieveLayout,
  },
  // /quotes/{quoteId}
  {
    match: /^\/quotes\/(\d+)(?:#location-(\d+))?$/,
    onMatch: ({ matches }) => {
      const quoteId = Number(matches[1]);
      document.title = 'RQ ' + quoteId + ' - Rocket®';
    },
    getController: ({ matches }) => {
      const controller = new QuoteController();
      const quoteId = Number(matches[1]);
      const locationId = Number(matches[2]);

      controller.addOrFireInitEvent(controller.asyncRequestQuoteRetrieve.bind(controller, quoteId, locationId));

      return controller;
    },
    view: QuotePageLayout,
  },
  // /origquoteid/{origQuoteId}
  {
    match: /^\/origquoteid\/(\d+)(?:#location-(\d+))?$/,
    onMatch: ({ matches, location }) => {
      const origQuoteId = Number(matches[1]);
      document.title = 'RQQID ' + origQuoteId + ' - Rocket®';
    },
    getController: ({ matches, location }) => {
      const origQuoteId = Number(matches[1]);
      return new OriginalQuoteController(origQuoteId, location.hash);
    },
    view: LandingPageLayout,
  },
  {
    match: /.+/,
    getController: () => new NotFoundController(),
    view: NotFoundLayout,
  },
];

export default Routes;
