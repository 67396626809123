import CommonSectionModel from './CommonSectionModel';
import { ORDER_TYPES, QUOTE_STATUS } from '../../common/enums';
import { AuthContext, PartnerContextModel, PriceBookContextModel } from '../../Context';
import config from '../../config';
import apiData from '../../Storage/apiData';
import bindMultiple from '../../common/helpers/bindMultiple';

class AdminSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'admin-section';
    this.title = 'msg_admin_section';
    this.isGeneralSection = true;

    bindMultiple(this, this.onExpirationDateOverrideChange, this.unsetExpirationOverride, this.onChangeDealerDemoKit);
  }

  __isFinancingOptionDisabled() {
    let disabled = false;
    const quotes = this.controller.quotes.items;

    for (let i = 0; i < quotes.length; i++) {
      if (![QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(quotes[i].quoteStatus)) {
        disabled = true;
        break;
      }
    }

    return disabled;
  }

  get showSpeechAnalyticsControls() {
    return Boolean(this.customerOrder.orderType !== ORDER_TYPES.NEW_CUSTOMER && this.isSupportsSpeechAnalytics);
  }

  get showAddOnFinancingTermMonths() {
    return Boolean(this.isAddon && this.isSupportsMatchAddOnTermLengthToLocationQuote && this.customerOrder.isFinanced);
  }

  get solutionTypeDropdownDisabled() {
    return Boolean(
      this.disabledState ||
        PriceBookContextModel.isSupportsSwitchvoxSIPStation ||
        this.customerOrder.standaloneServiceNonUCaaS ||
        this.customerOrder.sangomaCXStandalone
    );
  }

  get showCustomerSangomaCxControls() {
    return Boolean(
      this.customerOrder.orderType !== ORDER_TYPES.NEW_CUSTOMER && PriceBookContextModel.isSupportsSangomaCx
    );
  }

  get showSwitchvoxOverrides() {
    return Boolean(
      PriceBookContextModel.isSupportsSwitchvoxSIPStation &&
        this.isAddon &&
        this.customerOrder.switchvoxExistsInOriginalLocation
    );
  }

  get showContractRewrite() {
    return Boolean(
      this.customerOrder.orderType &&
        this.customerOrder.orderType !== ORDER_TYPES.REWRITE &&
        !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showCustomerDaasOverride() {
    return Boolean(
      [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType) &&
        this.isSupportsDaas &&
        !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get customerOverridesDisabled() {
    return Boolean([QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(this.customerOrder.quoteStatus));
  }

  get showRentalAgreementFields() {
    return Boolean(
      [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType) &&
        this.customerOrder.isRental &&
        !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showCreditDocumentsFields() {
    return Boolean(
      this.customerOrder.isAllInclusive &&
        [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType) &&
        !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get expirationOverrideDisabled() {
    return Boolean(![QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED].includes(this.customerOrder.quoteStatus));
  }

  get showFinancingOption() {
    return Boolean(
      PriceBookContextModel.isSupportsRental &&
        this.customerOrder.isFinanced &&
        !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get financingOptionDisabled() {
    return this.showFinancingOption ? this.__isFinancingOptionDisabled() : false;
  }

  get showAmendmentFields() {
    return Boolean(
      config.eSignatureEnabled &&
        this.customerOrder.quoteStatus === QUOTE_STATUS.AMENDING &&
        [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType)
    );
  }

  get showEsigFlowToggle() {
    return Boolean(
      config.eSignatureEnabled &&
        (!this.isAddon ||
          (this.isAddon && this.customerOrder.isRental) ||
          (this.isAddon &&
            PriceBookContextModel.isSupportsSwitchvoxSIPStation &&
            this.customerOrder.isSipStationDocumentsAvailable)) &&
        // Display toggle for internal+ users only if no API override is present
        (apiData.properties.esignature.allowAnyoneToChoosePrintFlow || AuthContext.model.hasInternalPermissions)
    );
  }

  get lineItemsLength() {
    return apiData.lineItems.length || 0;
  }

  get showInHouseSystemToggle() {
    return Boolean(!PriceBookContextModel.isSupportsSwitchvoxSIPStation);
  }

  get showDealerDemoKitToggleControl() {
    return Boolean(!PriceBookContextModel.isSupportsSwitchvoxSIPStation);
  }

  get showSkipProrationLabel() {
    return Boolean(this.isAddon);
  }

  get showSaSigningDateToggleControl() {
    return Boolean(!PartnerContextModel.isWhiteLabelWholesale);
  }

  get showSolutionTypeIdSelect() {
    return Boolean(this.isAddon);
  }

  get showExpirationDateControls() {
    return Boolean(
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType)
    );
  }

  onExpirationDateOverrideChange(value) {
    this.customerOrder.onChange('expirationDateOverride', value);
  }

  unsetExpirationOverride() {
    this.onExpirationDateOverrideChange(null);
  }

  onChangeDealerDemoKit(value) {
    return this.controller.onChangeDealerDemoKit(value);
  }
}

export default AdminSectionModel;
